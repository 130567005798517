@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');

/* stylelint-disable */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* stylelint-enable */

*,
::before,
::after {
  box-sizing: border-box;
}

html,
body,
#app {
  margin: 0;
  padding: 0;
  height: 100%;
  min-width: 320px;
  background-color: #04020f;
  font-family: 'Exo 2', sans-serif;
}

img,
video {
  max-width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.Portal {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.NavLink {
  color: #fff;
  text-decoration: none;
}

.FlexLink {
  display: flex;
}

.HoverUnderlineAnimation {
  position: relative;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.HoverUnderlineAnimation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.HoverUnderlineAnimation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

p,
ul {
  margin: 0;
}

.BellAnimation {
  transform: rotate(5deg);
  animation: ease-in 1s infinite bell-rot;
}

@keyframes bell-rot {
  0% {
    transform: rotate(0);
  }

  15% {
    transform: rotate(10deg);
  }

  30% {
    transform: rotate(-10deg);
  }

  45% {
    transform: rotate(6deg);
  }

  60% {
    transform: rotate(-6deg);
  }

  75% {
    transform: rotate(3deg);
  }

  85% {
    transform: rotate(-3deg);
  }

  87% {
    transform: rotate(1deg);
  }

  88% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0);
  }
}

.rdw-editor-toolbar {
  background: inherit;
  border-radius: 5px;
  border-color: rgba(255, 255, 255, 0.23);

  span {
    color: #04020f;
  }

  div {
    border-radius: 5px;
  }
}

.rdw-editor-main {
  background: inherit;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.23);
  min-height: 300px;
  height: auto;
  padding: 0 13px;
  max-height: 60vh;
}

.rdw-dropdown-optionwrapper {
  color: #04020f;
}

.matchedCard {
  box-shadow: 0 0 15px 5px rgba(150, 147, 217, 0.6);
  border-radius: 5px;
}

.fc-theme-standard .fc-scrollgrid,
.fc-theme-standard td,
.fc-theme-standard th {
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.12);
}

.fc .fc-daygrid-day.fc-day-today,
.fc-timegrid-slot-lane {
  //background-color: c;
}

.fc-scrollgrid-section-header .fc-scroller {
  overflow: hidden !important;
}

.cs-conversation__name,
.cs-conversation__info,
.cs-conversation-header__user-name,
.cs-conversation-header__info {
  color: #fff !important;
}

.cs-main-container > .cs-sidebar.cs-sidebar--left {
  border-right: solid 1px rgba(255, 255, 255, 0.12) !important;
}

.cs-conversation-header {
  border-bottom: solid 1px rgba(255, 255, 255, 0.12) !important;
  padding: 0 !important;
}

.cs-message-input {
  border-top: solid 1px rgba(255, 255, 255, 0.12) !important;
}

.cs-conversation-header,
.cs-sidebar--right,
.cs-typing-indicator,
.cs-expansion-panel,
.cs-expansion-panel--open .cs-expansion-panel__header,
.cs-message-input,
.cs-chat-container,
.cs-message-list,
.cs-conversation-header__user-name,
.cs-conversation-header__info,
.cs-message-separator {
  background-color: inherit !important;
}

.ActiveChatMessage {
  cursor: pointer;
}

.ChatMessage {
  background-color: #9fa8da !important;
  cursor: pointer;
}

.inputStyle {
  width: 3rem !important;
  height: 3rem;
  margin: 0 0.2rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 1);
  color: #04020f !important;
  margin-bottom: 10px;
}

.inputStyleError {
  border: 1px solid red;
}

.w-md-editor-preview,
.wmde-markdown,
.wmde-markdown-color {
  background: inherit !important;
}

.editorCustomStyle {
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.23);
  background-color: inherit !important;
}

.editorCustomStyle .w-md-editor-toolbar {
  border-radius: 5px;
  //border: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 5px 10px;
  background-color: inherit !important;
}

.editorCustomStyle .w-md-editor-bar {
  bottom: 5px;
  right: 5px;
}

.w-md-editor-preview {
  align-items: center;
}
